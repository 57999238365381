














































































import Vue, { PropType } from "vue";
import { get_color_by_matching_score } from "@/utils/colors";
import { get_job_icon } from "@/utils/jobs_icons";
import { format_overall_score } from "@/utils/global";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import router from "@/router";

import {
  FavJobActions,
  MatchingJob,
  ToggleJobFavStatusPayload,
  UpdateFavJobsStatus
} from "@/store/modules/candidates/interfaces";
import {
  TOGGLE_JOB_FAV_STATUS,
  TOGGLE_JOB_FAV_STATUS_LOADING,
  UPDATE_FAV_JOBS_STATUS
} from "@/store/modules/candidates/constants";
import { ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";

export default Vue.extend({
  name: "JobCard",
  data() {
    return {
      font_color: "",
      background_color: "",
      color_code: "",
      navigate_link: ""
    };
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters("candidate", {
      get_toggle_job_fav_status_loading: TOGGLE_JOB_FAV_STATUS_LOADING
    }),
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  props: {
    job: {
      type: Object as PropType<MatchingJob>,
      required: true
    },
    show_matching_score: {
      type: Boolean,
      default: true
    },
    navigation: {
      type: String,
      default: "/candidate/jobs/view/"
    },
    module: {
      type: String,
      default: "candidate"
    }
  },
  mounted() {
    const color = get_color_by_matching_score(this.job.score); // Get random color
    this.font_color = `color: ${color}`;
    this.background_color = `background: ${color}`;
    this.color_code = color;
    if (this.module === "candidate") {
      const id = this.job.jid;
      this.navigate_link = this.navigation + id;
    } else if (this.module === "recruiter-standardised")
      this.navigate_link = this.navigation + this.job.jid;
    else this.navigate_link = "";
  },
  watch: {
    loading(n) {
      if (!n) {
        this.reset_color();
      }
    }
  },
  methods: {
    ...mapMutations({
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("candidate", {
      toggle_job_fav_status: TOGGLE_JOB_FAV_STATUS
    }),
    ...mapMutations("candidate", {
      update_fav_job_status: UPDATE_FAV_JOBS_STATUS
    }),
    format_overall_score,
    /**
     * Extract job icon path from job title
     * @return string => job icon path
     */
    get_icon(): string {
      return get_job_icon(this.job?.job_title);
    },
    /**
     * Update svg path fill color and return svg
     * @param icon
     * @return HTMLElement => updated svg
     */
    transform_job_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children)
        node.setAttribute("fill", this.color_code);
      return icon;
    },
    async route_navigation() {
      sessionStorage.removeItem("job_id_next_move_assessment");
      sessionStorage.removeItem("next_move_job_details");
      // If recruiter viewing standardised profile
      if (this.module === "recruiter") {
        this.$emit("job_pressed", this.job);
      } else if (this.module === "candidate") {
        await router.push(this.navigate_link);
      } else if (this.module === "recruiter-standardised") {
        await router.push(this.navigate_link);
      }
    },
    /**
     * Function toggle job fav status
     */
    async toggle_job_status() {
      const payload: ToggleJobFavStatusPayload = {
        job_id: this.job.jid,
        action: this.job.is_favorite
          ? FavJobActions.DISLIKE
          : FavJobActions.LIKE
      };
      const response = await this.toggle_job_fav_status(payload);
      if (response) {
        const _payload: UpdateFavJobsStatus = {
          fav_jobs: [
            { job_id: this.job.jid, user_id: this.get_user_details.id }
          ]
        };
        this.update_fav_job_status(_payload);
        if (payload.action === FavJobActions.LIKE) {
          this.root_notification(this.$t("shared.job-liked"));
        } else {
          this.root_notification(this.$t("shared.job-disliked"));
        }
      }
    },
    reset_color() {
      const color = get_color_by_matching_score(this.job.score); // Get random color
      this.font_color = `color: ${color}`;
      this.background_color = `background: ${color}`;
      this.color_code = color;
    }
  }
});
